.ant-input-affix-wrapper,
.ant-input-affix-wrapper-lg,
.ant-select-selection-search-input {
    height: 40px;

    input {
        height: auto;
    }
}

.NavbarSearch {
    display: flex;
    flex-grow: 1;

    @media ( max-width: 767px ) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: 20px;
    }

    @media ( max-width: 540px ) {
        margin: 10px;
    }

    @media ( min-width: 768px ) {
        max-width: 600px;
    }

    .input {
        flex-grow: 1;

        .ant-select-selection-search-input {
            border-radius: 20px;

            .ant-input-prefix {
                margin-right: 8px;
                margin-left: 4px;
            }
        }
    }
}

.NavbarSearchDropdown {
    .ant-select-dropdown-menu {
        max-height: 400px;
    }

    .ant-select-item-group {
        font-weight: 500;
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }

    .ant-select-item {
        padding-left: 16px;
    }

    .result {
        display: flex;
        align-items: center;

        > *:not( :last-child ) {
            margin-right: 10px;
        }

        img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }

        .name {
            font-weight: 500;
        }
    }
}
