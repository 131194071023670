@import "src/styles/main";
@import "src/styles/mixins";

$navbarCollapseBreakpoint: 1100px;
$mobileLogoBreakpoint: 540px;

.Navbar {
    display: flex;
    align-items: center;
    height: 94px;
    background-color: white;

    @media ( max-width: ( $tablet-min-width - 1px ) ) {
        height: 60px;
    }

    &.clear {
        background-color: #ecedff;
    }

    .container {
        max-width: 1440px !important;
        padding: 0 28px;

        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .logo {
                height: 24px;
                margin-right: 20px;
                transition: opacity 0.2s;

                @media ( max-width: ( $md-max-width - 1px ) ) {
                    width: 250px;
                }

                @media ( max-width: ( $mobileLogoBreakpoint - 1px ) ) {
                    width: auto;
                }

                @media ( max-width: ( $mobileLogoBreakpoint - 1px ) ) {
                    height: 18px;

                    &.desktop {
                        display: none;
                    }
                }

                @media ( min-width: $mobileLogoBreakpoint ) {
                    &.mobile {
                        display: none;
                    }
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    .left {
        display: flex;
        margin-right: auto;
    }

    .right {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-end;

        .ant-btn {
            margin-left: 10px;
        }
    }

    .links {
        display: flex;

        @media ( max-width: $navbarCollapseBreakpoint ) {
            display: none;
        }

        .link {
            display: flex;
            align-items: center;
            font-weight: 500;

            .active,
            .active .ant-btn-link {
                color: var(--text-color);
            }

            .ant-btn-link {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    .dropdown-mobile {
        display: flex;
        align-items: center;

        @media ( min-width: ( $navbarCollapseBreakpoint + 1 ) ) {
            display: none;
        }
    }

    .dropdown-desktop {
        margin-left: 15px;

        @media ( max-width: $navbarCollapseBreakpoint ) {
            display: none;
        }
    }
}

.Notification.ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    line-height: 20px;

    .avatar {
        display: flex;
        //align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
        text-align: center;

        .icon {
            object-fit: cover;
            width: 60%;
            height: 60%;
            margin-top: 2px;
        }
    }

    .action-buttons {
        display: flex;
        margin: 8px 0;

        .ant-btn {
            width: 80px;
            margin: 0 12px;

            &:nth-child( 1 ) {
                margin: 0;
            }
        }
    }
}

.unread {
    background-color: $gray-2;
}

.navbar-dropdown {
    overflow: hidden;
    padding: 8px 0 !important;
    border-radius: 10px !important;

    .ant-dropdown-menu-item,
    .no-notifications {
        padding-right: 16px;
        padding-left: 16px;
    }
}
