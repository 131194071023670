// /*
// Imports antd by using less. This allows the default less variables to be updated with hot-reloading and antd css classes
// can also be changed here. But this removes intellisense for antd css classes.
// */

@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less"; // Importing Ant Design styles by less entry

// Overriding antd default less variables
@text-color: black;
@link-color: #4154FF;
@primary-color: #4154FF;
@btn-border-width: 0;
@btn-font-weight: 500;
@btn-default-color: #4154FF;
@btn-default-bg: #ECEDFF;
@btn-default-border: none;
@btn-default-ghost-bg: rgba(0, 0, 0, 0.5);
@btn-default-ghost-border: none;
@btn-text-shadow: none;
@btn-shadow: none;
@btn-primary-shadow: none;
@radio-button-bg: white;
@radio-button-checked-bg: #ECEDFF;
@radio-button-color: black;
@tag-default-bg: white;
@border-radius-base: 10px;

.ant-alert-muted {
    padding: 15px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.04);

    .ant-alert-message {
        color: #777;
    }
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background: #3549fe;
}

.ant-btn-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ant-select-lg,
.ant-input-lg {
    height: 40px;
}

.ant-form-item {
    margin-bottom: 15px;
}

.ant-form-item-label {
    font-weight: 500;
}

.ant-checkbox-inner {
    border-radius: 2px;
}

.ant-modal-header {
    padding: 24px;
    // margin: 0 15px;
    border-style: none;
    border-radius: 10px 10px 0 0;
}

.ant-modal-title {
    margin-top: -3px; // Line up with close button
    font-weight: bold;
    font-size: 24px;
    text-align: center;
}

.ant-modal-close {
    top: 6px;
    right: 6px;
}

.ant-modal-body {
    padding: 24px;
}

.ant-notification-notice {
    @media ( max-width: 767px ) {
        max-width: 320px;
    }
}

.ant-progress-inner:not( .ant-progress-circle-gradient ),
.ant-progress-circle-path {
    stroke: #4154ff;
}

// .ant-steps-item-icon {
//     margin-right: 30px;
// }
.ant-picker-input > input {
    color: #4154ff;
    text-align: center;
}

.ant-anchor-wrapper {
    background-color: white;
    // border-top: 1px solid rgba(black, 0.05);
    box-shadow: 0 0 2px rgba(black, 0.1);

    .ant-affix & {
        background-color: white;
        box-shadow: 0 1px 2px rgba(black, 0.1);
    }

    .container {
        display: flex;
    }

    .ant-anchor-ink {
        display: none;
    }

    .ant-anchor-link {
        display: flex;
        align-items: center;
        margin-right: 1.5rem;
        padding: 1rem 0;

        .ant-anchor-link-title {
            display: flex;
            align-items: center;
            color: var(--green-color);

            &.ant-anchor-link-title-active {
                color: inherit;
            }
        }
    }

    .ant-badge .ant-badge-count {
        margin-left: 5px;
        background-color: #1fdccb;
    }
}

// .ant-breadcrumb {
//     font-size: 0.85em;
//     letter-spacing: 0.05em;
//     font-weight: 500;
//     text-transform: uppercase;

//     .ant-breadcrumb-link {
//         a {
//             color: var(--green-color);
//         }
//     }
//     .ant-breadcrumb-separator {
//         opacity: 0.3;
//     }
// }
.ant-message-notice-content {
    text-overflow: ellipsis;
}
