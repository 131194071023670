@use "sass:math";

// Colors
$white: #fff;
$black: #141322;
$primary: var(--kit-color-primary);
$blue-light: #42baf9;
$blue-dark: #2c60e4;
$gray-1: #f0f8f3;
$gray-2: #e3efeb;
$gray-3: #d7e8e3;
$gray-4: #c3dbd3;
$gray-5: #a0c1b3;
$gray-6: #629383;
$yellow: #ff0;
$orange: #faad15;
$red: #f5222e;
$pink: #fd3995;
$purple: #652eff;
$green: #41b883;
$text: $gray-6;
$border: $gray-2;
$pastel-1: #f6f6f6;
$pastel-2: #ffedea;

// Accent colors
$default: $gray-4;
$secondary: $gray-5;
$success: $green;
$info: $blue-light;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;

// Font Family
$base-font-family: var(--kit-font-family);

// Font Size
$base-font-size: 15 !default;

// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 21px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Gradients
$gradient-main: #f1fff7 0%, #fff 50%, #f1fff7 100%;

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
    @return #{math.div(floor(math.div($px,$base) * 100),100)}rem;
    // For node-sass
    // @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
    // @return #{$px}px; // to pixels
}

// Transitions
@mixin transition-bg() {
    transition: background 0.2s ease-in-out;
}

@mixin transition-color() {
    transition: color 0.2s ease-in-out;
}

@mixin transition-fast() {
    transition: all 0.05s ease-in-out;
}

@mixin transition-middle() {
    transition: all 0.1s ease-in-out;
}

@mixin transition-slow() {
    transition: all 0.2s ease-in-out;
}

// Responsive utils
// TODO: Replace usage with the improved variables given below
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;

// Extra Large Screens
$xl-monitor-min-width: 1824px;
// Desktops/Laptops
$monitor-min-width: 1200px;
// Tablets
$tablet-max-width: 1024px;
$tablet-min-width: 481px;
// Smartphones
$phone-max-width: 480px;
$phone-min-width: 320px;

:export {
    primary_color: $primary;
}
