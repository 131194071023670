.Footer {
    padding: 60px 0;
    background-color: rgba(black, 0.02);

    @media ( max-width: 399px ) {
        text-align: center;
    }

    .grid {
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 30px;

        @media ( max-width: 991px ) {
            grid-template-columns: 1fr;
        }
    }

    .logo {
        max-width: 240px;
        margin-bottom: 20px;
    }

    p {
        max-width: 350px;
    }

    .social {
        display: inline-flex;
        align-items: center;

        a:not( :last-child ) {
            margin-right: 20px;
        }
    }

    .links {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;

        @media ( max-width: 767px ) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ( max-width: 399px ) {
            grid-template-columns: 1fr;
        }

        .links-section {
            div {
                margin-bottom: 10px;
            }

            button {
                margin-top: 5px;
            }

            a {
                color: inherit;
            }
        }
    }
}
