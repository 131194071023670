@import "src/styles/mixins";

.account-exists {
    display: flex;
    flex-flow: row wrap;
    row-gap: 18px;
    align-items: center;
    justify-content: space-evenly;
    padding: 24px;

    a {
        flex-basis: 100%;
    }

    .button {
        width: 100%;
        border-radius: 20px;
    }
}

.auth-container {
    margin-bottom: 24px;
}

.loader-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    height: 650px;
}

.loader {
    position: absolute;
    top: 35%;
}

.legal-text {
    margin-top: 10px;
}

.exception-text {
    display: flex;
    justify-content: space-between;
    margin: 16px 0 0;
}

// .change-prompt {
//   padding: 0;
//   font-size: 16px;
// }
[role="alert"] {
    padding: 12px 0;
}

.social-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;

    .social-button {
        display: inline-flex;
        align-items: center;
        height: 57px;
        border: 1px solid #ddd !important;

        &__icon {
            width: 25px;
            height: 25px;
        }
    }
}

// .login-divider {
//   text-align: center;
//   margin: 5px;
//   // font-weight: 500;
// }

//.input-field {
//}
.verification {
    text-align: center;

    .icons {
        font-size: 64px;
    }

    .buttons {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-bottom: 20px;
    }
}

.forgot_password {
    &__text {
        font-weight: 400;
    }
}

.confirmation {
    padding: 20px;

    &__text {
        padding: 0 10px;
        font-weight: 400;
    }

    &__note {
        padding: 0 10px 15px;
        color: #898d8b;
    }
}

.form-label {
    padding: 0 0 3px 10px;
    color: #767676;
    font-weight: 400;
    font-size: 16px;
}

// .account-type {
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: space-between;
//   place-items: center;
//   padding: 0;
//   margin: 0;

//   &__key {
//     padding: 0 10px 3px;
//     font-size: 15px;
//     font-weight: 400;
//     color: #767676;
//     flex-basis: 50%;
//   }

//   &__value {
//     flex-basis: 50%;
//     text-align: center;
//   }
// }
.confirm {
    width: 100%;
    margin: 30px 0 10px;
}

.dark {
    .ant-modal-content,
    .ant-modal-header {
        background: linear-gradient(to right, $gradient-main);
        transition: background-color 1s;
    }

    .ant-select-selector {
        color: $primary;
        font-weight: 450;
    }
}

.light {
    .ant-modal-content,
    .ant-modal-header {
        background: #fff;
    }
}

.change_email_address {
    text-align: left;

    .important_info {
        color: #8d8989;
        font-weight: bold;
        font-size: small;
    }

    .change-email-btn {
        display: flex;
        justify-content: right;
    }
}
