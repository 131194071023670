@import "mixins";

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031;
    width: 100%;
    height: 5px;
    //background:linear-gradient(-120deg, #5ac4a1, #7e9ddb, #5ac4a1, #d26080);
    //background: $primary; /* alternative # 1 */
    //background: linear-gradient(to bottom, #000428, #004e92); /* alternative # 2 */
    background: linear-gradient(to right, $primary, #fff, $primary); /* alternative # 3 */
}

/* Fancy blur effect */
#nprogress .peg {
    position: absolute;
    right: 0;
    display: block;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0, -4px);
    -ms-transform: rotate(3deg) translate(0, -4px);
    transform: rotate(3deg) translate(0, -4px);
}

.nprogress-custom-parent {
    position: relative;
    overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
