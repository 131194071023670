@import "src/styles/mixins";

.Error {
    align-items: center;
    justify-content: center;
    max-width: rem(600);
    margin: 250px auto;
    padding-right: rem(20);
    padding-left: rem(20);
    border-style: solid;
    border-color: #f1f1f1;
    border-radius: 15px;
    text-align: center;

    &-container {
        margin-bottom: auto;
        padding: 30px;
        color: $dark;
    }

    &-heading {
        margin-bottom: 15px;
        font-weight: bold;
    }

    &-button {
        margin-top: 20px;
    }
}
