@import "src/styles/mixins";

.ant-modal {
    .footer {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-evenly;
        padding: 16px 24px;

        &__button {
            &__25 {
                flex-basis: 30%;
            }

            &__50 {
                flex-basis: 45%;
            }

            &__75 {
                flex-basis: 60%;
            }

            &__text {
                letter-spacing: 1px;
            }
        }
    }
}
